.top-bar {
  position: sticky;
  display: flex;
  padding: 0 clamp(8px, 4vw, 20px);
  background-color: var(--main-color);
  width: 100%;
  height: clamp(40px, 10vw, 90px);
  top: 0;
  justify-content: flex-start;
  align-items: center;
  gap: clamp(12px, 4vw, 40px);
}

.top-bar img {
  height: 50%;
}

.top-bar .break {
  background-color: var(--heading-color);
  height: 70%;
  width: clamp(1px, 0.7vw, 3px);
  border-radius: 100px;
  opacity: 0.2;
}

.top-bar .img-link {
  display: flex;
  height: 43%;
  align-items: center;
}

.top-bar .img-link img {
  height: 100%;
  opacity: 0.5;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.top-bar .img-link img:hover {
  opacity: 0.9;
}

.top-bar .theme-toggle {
  position: relative;
  background-color: var(--accent-color);
  height: 40%;
  /* width: clamp(20px, 9vw, 60px); */
  aspect-ratio: 1.75 / 1;
  border: none;
  border-radius: 50px;
  margin-left: auto;
  cursor: pointer;
  transition: 0.4s background-color;
}
.top-bar .theme-toggle::before {
  content: '';
  background-color: var(--heading-color);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50px;
  transition-duration: 0.4s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.000, 0.000, 0.030, 0.950);
}

.top-bar .theme-toggle.light::before {
  transform: translateX(calc(75% + 1px));
}

