@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Dosis&family=Roboto:wght@300&family=Ubuntu+Mono&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

h1 {
  color: var(--heading-color);
  font-family: 'Dosis', sans-serif;
  font-size: clamp(5px, 7vw, 33px);
}

p {
  font-family: 'Roboto', cursor;
  font-size: clamp(3px, 5vw, 20px);
  line-height: 1.5em;
}

a {
  position: relative;
  font-family: 'Roboto', cursive;
  font-size: clamp(3px, 5vw, 20px);
  color: var(--link-color);
  text-decoration-color: rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: color, text-decoration-color;
}
a:hover {
  color: var(--link-hover-color);
  text-decoration-color: var(--link-hover-color);
}

br {
  display: block;
  content: "";
  margin-top: 0.5em;
}

code {
  font-family: 'Ubuntu Mono', monospace;
}

.accent {
  color: var(--accent-color);
}

