.project {
  background-color: var(--container-color);
  display: flex;
  padding: clamp(5px, 10vw, 40px);
  width: 500px;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 20px;
}

.project * {
  flex-basis: 100%;
}

.project h1 {
  font-weight: normal;
}

.project a {
  text-align: right;
  margin-left: auto;
  flex-basis: auto;
  align-self: flex-end;
}

